import * as React from "react"

import Seo from "../../components/seo"
import Slider from "../../components/corebook-rumours.js"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Rumours | Books | E.O. Chirovici'
        
        description='Rumours, a non-fiction book written by Eugen Ovidiu Chirovici, also known as E.O. Chirovici, and who is the writer of the Book of Mirrors '

        keywords='Rumours, Eugen Ovidiu Chirovici, E.O. Chirovici, The Book of Mirrors, Sleeping Dogs, Sleeping Dogs The Film, Sleeping Dogs Movie'

      />

<Helmet>

</Helmet>



<Slider/>
     
    </div>
  )

}


export default mainPage;