import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import Grid from '@mui/material/Grid';


import Breadcrumbs from '@mui/material/Breadcrumbs';

import "swiper/css";

import {Navigation } from "swiper";
import Paper from '@mui/material/Paper';



import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import { MdOutlineSwipeLeft } from "react-icons/md/";
import { MdOutlineSwipeRight } from "react-icons/md";

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviewImages = [
  {
    label: "Picture of of the newspaper logo The Telegraph in the context of the newspaper's review of E.O. Chirovici's Bad Blood ",
    text: ' Rumors That Changed the World: A History of Violence and Discrimination is an interesting, useful and well-structured book that aims to identify the way in which rumors, as a psycho-social phenomenon, have played a part in triggering and shaping various historical events.  ',

    imgPath:
      '/images/reviews/Romanian-Academy.png',
  }
 
];








export default function App(props) {

  

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviewImages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };




  return (

      <>
  <Swiper
        className='bookPage'
        spaceBetween={0}

                centeredSlides={false}



        grabCursor={false}
        resistance={false}  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/books/gods-weapons-and-money');    };

              if (swiperCore.realIndex === 0) {    navigate('/books/bad-blood');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white switchToSLC books'> 





<div className='longPage'>




<span className='standardIntroductionText standardBookIntroductionText animate__animated'>


    <Breadcrumbs aria-label="breadcrumb">
        <Link className='breadcrumbLink' to="/">
          Home
        </Link>
          <Link className='breadcrumbLink' to="/books">
          Books
        </Link>
        <Link className='breadcrumbLink' to="/books/rumours">
          Rumours
        </Link>




      </Breadcrumbs>



<h3  className=' animate__animated'>Rumours</h3>

</span>


<Grid className='bookGrid' container spacing={2}>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Non-Fiction </span>


  </Grid>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>2018 </span>


  </Grid>
  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Lexington Books </span>


  </Grid>









  </Grid>







<span className='standardIntroductionText animate__animated'>

<p className='animate__animated'>

The aim of the book is to explore the social and cultural impact of rumor from Antiquity to the mid-1990s, examining it as one of the most important contributing factors to violence and discrimination. Usually defined as an unverified account that circulates from one person to another and refers to an object, event, or matter of public interest, rumor and its impact have largely been ignored by scholars and authors. The aim of the book is to explore the social and cultural impact of rumor from Antiquity to the mid-1990s, examining it as one of the most important contributing factors to violence and discrimination. Usually defined as an unverified account that circulates from one person to another and refers to an object, event, or matter of public interest, rumor and its impact have largely been ignored by scholars and authors. 
<br/><br/>

Eugen O. Chirovici has tried not only to describe a number of major historic events, but also to explain how the rumors that influenced them came into being and to account for the collective desires or fears that nurtured them. Merely to conclude that the human mind has always been vulnerable to rumors, sometimes with lethal consequences, is not enough; it is important to understand not only what happens, but also why it happens. For at least three reasons, Chirovici thinks that it is important-particularly in this era of explosive development in mass communications-to understand the complex mechanisms whereby rumors emerge and spread.


<br/><br/>



 The first is that history has taught us that in certain circumstances rumors can be extremely dangerous, being employed as tools of manipulation, disinformation, and propaganda. The second relates to a deeper understanding of the way in which the most recent inventions-the Internet, social networks, digital landscape-affect and will go on affecting our lives; the virtual world is a historically unprecedented vehicle for the dissemination of rumors. And the third has to do with the wider and more nebulous idea of progress. In other words, are we less vulnerable to rumors today than we were, for example, in the Middle Ages? 


</p>
</span>




  <Box className='reviewBox roundPicture swiper-no-swiping' sx={{ maxWidth: 400, flexGrow: 1 }}>
  


<AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviewImages.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (

<div>
<span className='reviewText'> {step.text} </span>

<br/><br/>

              <Box
                component="img"
                sx={{
                  height: 140,
                  display: 'inline-block',
                  maxWidth: 140,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
          </div>

            )  : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        variant='progress'
    
      />
    </Box>



<div className='bookNavigationButtons'>


<Link className='bookpageButton Left' to='/books/bad-blood'>

<MdOutlineSwipeLeft />

  Bad Blood


</Link>

<Link className='bookpageButton Right' to='/books/gods-weapons-and-money'>

Gods, Weapons and Money

<MdOutlineSwipeRight />


</Link>


</div>






</div>




</SwiperSlide>




























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}